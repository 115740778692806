import {
  ModalInfo,
  ModalInfoMembresias,
  ModalInfoPlan,
  ModalPhoto,
  instance,
  NuevaAxiosURL,
  ButtonMob,
  laptop575,
  
  
} from "../../utils/utils";
import React, { useRef } from "react";
import "./dashboard.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import iconoFlechaElevva from "../../assets/newIcons/ICO-FLECHA-ELEVVA.svg"
import icoPositivo from "../../assets/newIcons/ICO-POSITIVO.svg"
import { ResponsiveLine } from "@nivo/line";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Navigation } from "swiper";
import bicicleta from "../../assets/assets/bicicletaT.png"
import { ReactComponent as IconCaminar } from "../../assets/newIcons/ICO-CAMINAR.svg";
import { ReactComponent as IconClock } from "../../assets/newIcons/ICO-CLOCK.svg";
import { ReactComponent as IconIntensity } from "../../assets/newIcons/ICO-INTENSITY.svg";
import { ReactComponent as IconCaminata } from "../../assets/newIcons/ICO-CAMINATA.svg";
//spinner

// import elevva from "../../assets/img/general/logo"
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
// Buton for class grups
import Moment from "moment";
import { extendMoment } from "moment-range";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { AllPromedios, ButtonTutorial, ComponentIcons, ComponentName, IngresaTension, NextClass, NivelCustomer, PromediosPanel, Proximas, Puntoselevva, RangeProgress, SemanaCustomer, SwiperPlan, TestSlider } from "./helpResponsive";
import { useCompareDate } from "../Mis clases/misclases_utils";
import { ReactComponent as ValoraIco } from "../../assets/newIcons/ICO-PLAN-VALORACION.svg";
import { ReactComponent as TrainIcon } from "../../assets/newIcons/ICO-PLAN-ENTRENAMIENTO.svg";
import { ReactComponent as CardioIcon } from "../../assets/newIcons/ICO-PLAN-CARDIO.svg";
import { ReactComponent as ReposoIcon } from "../../assets/newIcons/ICO-PLAN-REPOSO.svg";
import { ReactComponent as VideoIcon } from "../../assets/newIcons/ICO-PLAN-VIDEO.svg";
import { ReactComponent as IconAerobic } from "../../assets/newIcons/aerobica_person.svg";
import { ReactComponent as IconPresencial } from "../../assets/newIcons/type_presencial.svg";
import { ReactComponent as NutriIcon } from "../../assets/newIcons/ICO-PLAN-NUTRICION.svg";
import { ReactComponent as ValoraNutricional } from "../../assets/newIcons/valora_nutricional.svg";
import { ReactComponent as GrupalIcon } from "../../assets/newIcons/grupalIcon.svg";
import { iconReview, iconReviewColor, mestraining } from "../Perfil/helpPerfil";
import { whatMonth } from "../TutorialReserva/stepsHelp";

const Dashboard = (props) => {
  const stepsMobile = [
    {
      content: (
        <div
          className="d-flex "
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <div className="mx-auto ">
            {" "}
            <img
              style={{ width: "100%", backgroundImage: "contain" }}
              src={process.env.PUBLIC_URL + "/img/MODELOYoce.png"}
            />
          </div>
          <div className="d-flex justify-content-center flex-column gap-3 pt-5">
            <h3
              className=""
              style={{
                color: "#394F65",
                fontWeight: "500",
                fontSize: "1.2rem",
              }}
            >
              Bienvenido a nuestra plataforma de
              <br /> bienestar elevva
            </h3>
            <span
              style={{
                color: "#9DACBD",
                fontWeight: "600",
                fontSize: "1.0rem",
              }}
            >
              Acá encontraras el contenido <br />
              perfecto para iniciar un estilo <br />
              de vida saludable que te hará <br />
              pleno y feliz.
            </span>
          </div>
        </div>
      ),

      locale: {
        skip: (
          <span
            aria-label="skip"
            onClick={() => {
              var formData1 = new FormData();
              formData1.append("check_main_tutorial", true);
              instance
                .patch(`customers/`, formData1)
                .then((res) => res.data)
                .catch((err) => {
                  console.log("err", err);
                });
            }}
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
      },
      spotlightClicks: true,
      placement: "center",
      target: "body",
    },
    {
      target: "body",
      placement: "center",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          En tu panel de control podrás visualizar fácilmente todos los accesos
          de nuestra plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{
              textDecoration: "underline",
              color: "#CB3D82",
              fontSize: "0.9rem",
            }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#panelDesplegable",
      placement: "bottom",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          En el panel superior encontrarás acceso a otros accesos de nuestra
          plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#perfilCustomerT",
      placement: "bottom",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Acá tendrás acceso rápido a tu perfil además de ver tus puntos elevva
          acumulados, clases restantes, tu plan actual e histórico de
          entrenamiento semanal.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#totalClasesTutorialMobi",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Acá encontrarás el resumen general de tu actividad y entrenamiento
          dentro de la plataforma.
        </div>
      ),
      spotlightPadding: 30,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#infoArterialMobile",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Acá podrás ingresar fácilmente la información de tu tensión arterial
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#coverflowSlider",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Si deseas ver información más especifica de tu actividad, así como el
          porcentaje de progreso de tu entrenamiento, la encontrarás acá.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#coverflowSlider",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Podrás verificar el histórico de tus sesiones con información
          detallada de tu rendimiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#coverflowSlider",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          En esta sección podrás acceder a tus datos de medidas corporales y su
          progreso.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#proximasMobile",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Podrás acceder rápidamente al contenido de tu plan de entrenamiento y
          reserva de tus clases
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#proximoBookMobile",
      placement: "top",
      content: (
        <div style={{ color: "#394F65", fontWeight: "400" }}>
          Por último, acá podrás gestionar tu siguiente actividad del plan de
          entrenamiento. Si el estado de tu clase es en espera de confirmación,
          tu clase aún no ha sido tomada; si al contrario dice confirmada, ya
          tienes un profesor asignado.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
  ];

  const steps = [
    {
      content: (
        <div
          className="d-flex "
          style={{ justifyContent: "center", flexDirection: "column" }}
        >
          <div className="mx-auto ">
            {" "}
            <img
              style={{ width: "100%", backgroundImage: "contain" }}
              src={process.env.PUBLIC_URL + "/img/MODELOYoce.png"}
            />
          </div>
          <div className="d-flex justify-content-center flex-column gap-3 pt-5">
            <h3
              className=""
              style={{
                color: "#394F65",
                fontWeight: "500",
                fontSize: "1.2rem",
              }}
            >
              Bienvenido a nuestra plataforma de
              <br /> bienestar elevva
            </h3>
            <span
              style={{
                color: "#9DACBD",
                fontWeight: "600",
                fontSize: "1.0rem",
              }}
            >
              Acá encontraras el contenido <br />
              perfecto para iniciar un estilo <br />
              de vida saludable que te hará <br />
              pleno y feliz.
            </span>
          </div>
        </div>
      ),

      locale: {
        skip: (
          <span
            onClick={() => {
              var formData1 = new FormData();
              formData1.append("check_main_tutorial", true);
              instance
                .patch(`customers/`, formData1)
                .then((res) => res.data)
                .catch((err) => {
                  console.log("err", err);
                });
            }}
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
      },
      spotlightClicks: true,
      placement: "center",
      target: "body",
    },

    {
      target: "#Barra0",
      placement: "right",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          En el panel lateral encontraras acceso a los diferentes segmentos de
          nuestra plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      placement: "center",
      target: "body",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          En tu panel de control podrás visualizar fácilmente todos los accesos
          de nuestra plataforma.
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },

    {
      target: "#tensionArterialPanel",
      placement: "right",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Acá podrás ingresar fácilmente la información de tu tensión arterial
        </div>
      ),

      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#bodyPanel",
      placement: "right",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          En esta sección podrás acceder a tus datos de medidas corporales y su
          progreso.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#promediosPanel",
      placement: "bottom",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Acá encontrarás el resumen general de tu actividad y entrenamiento
          dentro de la plataforma.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: ".activityandprogress",
      placement: "left",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Si deseas ver información más especifica de tu actividad, así como el
          porcentaje de progreso de tu entrenamiento, la encontrarás acá.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#sesionesrecientes",
      placement: "top",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Podrás verificar el histórico de tus sesiones con información
          detallada de tu rendimiento.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#userInfoPanel",
      placement: "left",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Acá tendrás acceso rápido a tu perfil además de ver tus puntos elevva
          acumulados, clases restantes, tu plan actual e histórico de
          entrenamiento semanal.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#actividadesPlanTrainer",
      placement: "left",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Podrás acceder rápidamente al contenido de tu plan de entrenamiento y
          reserva de tus clases
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,
        skip: (
          <span
            aria-label="skip"
            style={{ textDecoration: "underline", color: "#CB3D82" }}
          >
            Finalizar tutorial
          </span>
        ),
        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
    {
      target: "#nextBookingPanel",
      placement: "left",
      content: (
        <div
          style={{
            color: "#9DACBD",
            fontWeight: "400",
            lineHeight: "110%",
            fontSize: "1rem",
          }}
        >
          Por último, acá podrás gestionar tu siguiente actividad del plan de
          entrenamiento.<br></br>
          <br></br> Si el estado de tu clase es en espera de confirmación, tu
          clase aún no ha sido tomada; si al contrario dice confirmada, ya
          tienes un profesor asignado.
        </div>
      ),
      spotlightPadding: 0,
      locale: {
        last: <span aria-label="skip">Finalizar tutorial</span>,

        next: <span aria-label="skip">Siguiente</span>,
        back: <span aria-label="skip">Anterior</span>,
      },
    },
  ];
  const [userData, setUserData] = useState([]);
  const [planDaySelected, setplanDaySelected] = useState(0);
  const [stepIndex, setstepIndex] = useState(0);
  const [correr, setcorrer] = useState(false);
  const [heightjoyride, setheightjoyride] = useState(false);
  const [tensionArterial, setTensionArterial] = useState(0);
  const [tensionArterial1, setTensionArterial1] = useState(0);
  const [counter, setcounter] = useState(null);
  const [BlockBody, setBlockBody] = useState(false);
  const [BlockActivity, setBlockActivity] = useState(false);
  const [BlockTrainingPlan, setBlockTrainingPlan] = useState(false);
  const [BlockSessions, setBlockSessions] = useState(false);
  const [counterStorage, setcounterStorage] = useState(null);
  const [trainings, setTrainings] = useState([]);
  const [tutorialCheck, setTutorialCheck] = useState(true);
  const [arterial_prom, setarterial_prom] = useState("---/--");
  const [cardiaca_prom, setcardiaca_prom] = useState("---");
  const [recorrida_prom, setrecorrida_prom] = useState("---");
  const [calorias_prom, setcalorias_prom] = useState("---");
  const [ProgressLevel, setProgressLevel] = useState(0);
  const [copyAllEntrenamientos, setCopyAllEntrenamientos] = useState(null);
  const [brazoiz, setbrazoiz] = useState(0);
  const [brazoizgrasa, setbrazoizgrasa] = useState(0);
  const [brazode, setbrazode] = useState(0);
  const [brazodegrasa, setbrazodegrasa] = useState(0);
  const [piernaiz, setpiernaiz] = useState(0);
  const [piernaizgrasa, setpiernaizgrasa] = useState(0);
  const [piernade, setpiernade] = useState(0);
  const [piernadegrasa, setpiernadegrasa] = useState(0);
  const [abdomenp, setabdomen] = useState(0);
  const [abdomenpgrasa, setabdomengrasa] = useState(0);
  const [mesPrograma, setmesPrograma] = useState([]);
  const [Range, setRange] = useState("lm");
  const [Type, setType] = useState(99);
  const [frecuencyUp, setfrecuency] = useState("day");
  const [modalInfoCardio, setModalInfoCardio] = useState(null);
  const [yMax, setyMax] = useState(100);
  const [yMin, setyMin] = useState(0);
  const [notifications, setnotifications] = useState(null);
  const location = useLocation();
  const [data, setData] = useState([
    {
      id: "Calorias",
      data: [
        { x: "2023-05-01", y: 0 },
        { x: "2023-05-02", y: 20 },
        { x: "2023-05-05", y: 60 },
        { x: "2023-05-07", y: 60 },
        { x: "2023-05-10", y: 20 },
      ],
    },
  ]);
  let timer;
  let graphics;
  let promedios;
  // promedios
  const updatePromedios = () => {
    if (
      (stepIndex == 5 && window.innerWidth > 768) ||
      (stepIndex == 4 && window.innerWidth < 768)
    ) {
      promedios =
        !promedios &&
        setInterval(() => {
          if (arterial_prom == "120/80") {
            setarterial_prom("---/--");
            setcardiaca_prom("---");
            setrecorrida_prom("---");
            setcalorias_prom("---");
          } else {
            setarterial_prom("120/80");
            setcardiaca_prom("120");
            setrecorrida_prom("5000");
            setcalorias_prom("105.5");
          }
        }, 1000);
    } else {
      clearInterval(promedios);
    }
  };
  const xAxisFormat = (date) => {
    let range =
      Range == "cw" || Range == "cw"
        ? "ddd"
        : Range == "cm" || Range == "lm"
        ? "MMM d"
        : "MMMM";
    return moment(date).format(range);
  };
  useEffect(() => {
    updatePromedios(stepIndex);

    return () => clearInterval(promedios);
  }, [arterial_prom, stepIndex]);

  function avisoBloqueo() {
    return setModalInfo(
      <ModalInfo
        error="false"
        handleClick={hideModal}
        textButtonConfirmar={"Mejorar mi plan"}
        classcorrecta={"recuerdaTitleBlock"}
        handleCancellcargue={hideModal}
        title={`Tu plan actual no te </br> permite acceder </br> a esta clase de </br>contenido </br>`}
        text={`</br>Contacta a tu asesor especializado para mejorar tu </br> plan y obtener mejores beneficios. </br> </br>`}
        classesMsg="colorText_Modal"
      />
    );
  }
  //hllo
  useEffect(() => {
    if (localStorage.profile_pic) {
      instance
        .get("app/notifications/unread")
        .then((res) => res.data)
        .then((data) => {
          setnotifications(data);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  function darsigno(x, y) {
    if (x - y >= 0) {
      return [+x];
    } else {
      return [-x];
    }
  }

  function dejarsolonumber(x) {
    if (x < 0) {
      return x * -1;
    } else {
      return x;
    }
  }
  //   function CardioTraining(elemento) {

  //     let e=elemento[0]
  //     let styleModal = {
  //       display: "flex",
  //       position: "fixed",
  //       zIndex: 9999,
  //       whiteSpace: "pre-line",
  //     };

  //     setModalInfoCardio(
  //       <div
  //         className="modalInfo justify-content-center align-items-center"
  //         style={styleModal}
  //       >
  //         <div className="modalInfoContenido" style={{maxWidth:"100%"}}>
  //         <div
  //             className=""
  //             style={{
  //               position: "absolute",
  //               top: "5%",
  //               right: "5%",
  //               fontWeight: "bold",
  //               fontSize: "1.5rem",
  //               color: " #9DACBD",
  //               cursor: "pointer",
  //             }}
  //             onClick={()=>setModalInfoCardio(null)}
  //           >
  //             <i className="bi bi-x-circle"></i>
  //           </div>

  //             <div className="title col-12 mb-3" style={{lineHeight:"100%",textAlign:"center",fontSize:"1rem"}}>Programa de Cardio / Mes {whatMonth(elemento[1])}</div>
  //           <div className="col-12 d-flex justify-content-center my-2">
  //           <button
  //                         className="mx-auto d-flex relative"
  //                         style={{
  //                           color: "var(--white)",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "center",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "var(--elevva)",
  //                           borderRadius: "20px",
  //                           border: "1px solid var(--elevva)",

  //                         }}
  //                       >
  //                        Día plan entrenamiento: {elemento[1]}
  //                       </button>
  //                       <button
  //                         className="mx-auto d-flex relative"
  //                         style={{
  //                           color: "var(--white)",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "center",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "var(--elevva)",
  //                           borderRadius: "20px",
  //                           border: "1px solid var(--elevva)",

  //                         }}

  //                       >
  //                      {e.label.replace("Cardio","")}
  //                       </button>

  //  </div>
  //  <div className="d-flex m-auto  " style={{background:"#F4F0ED",borderRadius:"40px",padding:"5% 2%",minWidth:"20rem"}}>
  // <div className="m-auto">
  // <div className="col-3 d-flex flex-column m-auto justify-content-center"  style={{background:"var(--elevva)",borderRadius:"100%", width:"50px",height:"50px",alignItems:"center"}}>
  //   <div className="d-flex flex-column m-auto justify-content-center"  style={{width:"25px",height:"25px"}}><IconCaminar/></div>
  //   </div></div>

  // <div className="d-flex flex-column m-auto " style={{textAlign:"left"}}>
  //   <div className="fw-bold">{e.detail.type}</div>
  //   <div className="m-auto d-flex " style={{alignItems:"center"}}><IconClock/><span className="ms-1"> {e.detail.duration} min</span></div>
  // </div>
  // <div className="d-flex flex-column m-auto" style={{textAlign:"left"}}>
  // <div className="my-auto d-flex " style={{alignItems:"center"}}><IconIntensity/><span className="ms-1"> Intensidad {e.detail.intensity}</span></div>
  // <div className="my-auto mx-1 d-flex " style={{alignItems:"center"}}><IconCaminata/><span className="ms-1">{e.detail.type}</span></div>

  // </div>

  //  </div>
  //  <div className="col-12 d-flex justify-content-center my-2">
  //  <button
  //                         className="mx-auto d-flex relative col-6"
  //                         style={{
  //                           color: "#394F65",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "start",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "#F4F0ED",
  //                           borderRadius: "20px",
  //                           border: "1px solid #F4F0ED",

  //                         }}

  //                       >
  //                  Metodología
  //                       </button>
  //                       <button
  //                         className="mx-auto d-flex relative col-5"
  //                         style={{
  //                           color: "var(--white)",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "center",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "var(--elevva)",
  //                           borderRadius: "20px",
  //                           border: "1px solid var(--elevva)",

  //                         }}

  //                       >
  //                     {e.detail.method}
  //                       </button>

  //  </div>
  //  <div className="col-12 d-flex justify-content-center my-2">
  //  <button
  //                         className="mx-auto d-flex relative col-6"
  //                         style={{
  //                           color: "#394F65",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "start",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "#F4F0ED",
  //                           borderRadius: "20px",
  //                           border: "1px solid #F4F0ED",

  //                         }}

  //                       >
  //                  Objetivo
  //                       </button>
  //                       <button
  //                         className="mx-auto d-flex relative col-5"
  //                         style={{
  //                           color: "var(--white)",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "center",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "var(--elevva)",
  //                           borderRadius: "20px",
  //                           border: "1px solid var(--elevva)",

  //                         }}

  //                       >
  //                      {e.detail.target}
  //                       </button>

  //  </div>
  //  <div className="col-12 d-flex justify-content-center my-2">
  //  <button
  //                         className="mx-auto d-flex relative col-6"
  //                         style={{
  //                           color: "#394F65",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "start",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "#F4F0ED",
  //                           borderRadius: "20px",
  //                           border: "1px solid #F4F0ED",

  //                         }}

  //                       >
  //              Zona de frecuencia cardiaca
  //                       </button>
  //                       <button
  //                         className="mx-auto d-flex relative col-5"
  //                         style={{
  //                           color: "var(--white)",
  //                           fontSize:"calc(0.6rem + 0.5vmin)",
  //                           padding: "2% 5%",
  //                           justifyContent: "center",
  //                           alignContent: "center",
  //                           alignItems: "center",
  //                           verticalAlign: "middle",
  //                           background: "var(--elevva)",
  //                           borderRadius: "20px",
  //                           border: "1px solid var(--elevva)",

  //                         }}

  //                       >
  //                   {e.detail.hr_zone}
  //                       </button>

  //  </div>
  //  <div className="title col-12 my-2" style={{lineHeight:"100%",textAlign:"center",fontSize:"1rem"}}>Observaciones</div>

  //  <PerfectScrollbar  className="title col-12 my-2 mx-auto" style={{lineHeight:"100%",textAlign:"center",maxWidth:"20rem",height:"5rem"}}>
  //  <span style={{fontSize:"0.8rem",color:"#9DACBD",}}>{e.detail.notes}   </span></PerfectScrollbar>

  //         </div>
  //       </div>
  //     );
  //   }
  function CardioTraining(elemento) {
    let e = elemento[0];
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 99999999,
      whiteSpace: "pre-line",
    };
    console.log(elemento);
    setModalInfoCardio(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ maxWidth: "100%", width: "calc(100vw - 73%)" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfoCardio(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title col-12 mb-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Mes {whatMonth(elemento[1])} <br></br>
            {elemento[0].label}
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: iconReviewColor(e.type),
                borderRadius: "20px",
                border: "1px solid transparent",
              }}
            >
              Día plan entrenamiento: {elemento[1]}
            </button>
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: iconReviewColor(e.type),
                borderRadius: "20px",
                border: "1px solid transparent",
              }}
            >
              <IconIntensity />
              <span className="ms-1"> {e.detail.intensity}</span>
            </button>
          </div>
          <div
            className="d-flex m-auto  "
            style={{
              background: "#F4F0ED",
              borderRadius: "40px",
              padding: "5% 4%",
              minWidth: "20rem",
            }}
          >
            <div className="my-auto">
              <div
                className="col-3 d-flex flex-column m-auto justify-content-center"
                style={{
                  borderRadius: "100%",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex flex-column m-auto justify-content-center"
                  style={{
                    cursor: "pointer",
                    borderRadius: "20px",
                    background: iconReviewColor(e.type),
                    padding: "0.5rem",
                    justifyContent: "center",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  {iconReview(e.type)}
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column m-auto "
              style={{ textAlign: "left" }}
            >
              <div className="my-auto d-flex " style={{ alignItems: "center" }}>
                <IconClock />
                <span className="ms-1"> {e.detail.duration} min</span>
              </div>
              <div
                className="my-1 mx-1 d-flex "
                style={{ alignItems: "center" }}
              >
                <IconPresencial />
                <span className="ms-1">
                  {e.detail.class_type || "No aplica"}
                </span>
              </div>
            </div>
            <div
              className="d-flex flex-column mb-auto"
              style={{ textAlign: "left" }}
            >
              <div className="m-auto d-flex " style={{ alignItems: "center" }}>
                <IconAerobic />
                <span className="ms-1">
                  {" "}
                  {e.detail.activity_type || "No aplica"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Objetivo
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.target}
            </button>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Metodología
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.method}
            </button>
          </div>

          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Zona de frecuencia cardiaca
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.cardio_zone || "No aplica"}
            </button>
          </div>

          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Zona corporal de trabajo
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.hr_zone || "No aplica"}
            </button>
          </div>
          {e.detail.notes ? (
            <div
              className="title col-12 my-2"
              style={{
                lineHeight: "100%",
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              Actividad del día
            </div>
          ) : null}

          <PerfectScrollbar
            className="title col-12 my-2 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "left",
              maxWidth: "20rem",
              height: "5rem",
            }}
          >
            <span style={{ fontSize: "0.8rem", color: "#9DACBD" }}>
              {e.detail.notes}{" "}
            </span>
          </PerfectScrollbar>
          {e.detail.description ? (
            <div
              className="title col-12 my-2"
              style={{
                lineHeight: "100%",
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              Observaciones
            </div>
          ) : null}

          <PerfectScrollbar
            className="title col-12 my-2 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "left",
              maxWidth: "20rem",
              height: "5rem",
            }}
          >
            <span style={{ fontSize: "0.8rem", color: "#9DACBD" }}>
              {e.detail.description}{" "}
            </span>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }

  function programaTraining() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalInfo(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ width: "calc(70rem + 2vmin)", maxWidth: "100%" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfo(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title mb-3 py-1 col-7 fw-light"
            style={{
              lineHeight: "100%",
              textAlign: "left",
              fontSize: "1.3rem",
            }}
          >
            <strong>Programa</strong> de entrenamiento
          </div>
          <div className="col-12 d-flex">
            <SwiperPlan
              userData={userData}
              mesPrograma={mesPrograma}
              CardioTraining={(e) => CardioTraining(e)}
              anchoGrid={"70%"}
              widthBox={"auto"}
              minWidthBox={"calc(5rem + 0.5vmin)"}
            />

            <div
              className="me-auto d-flex"
              style={{
                padding: "0 3%",
                width: "50%",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {/* ini */}
              {mestraining.map((day) => (
                <div
                  className="d-flex my-3"
                  style={{ alignItems: "center", width: "48%" }}
                >
                  <div
                    className="col-3 d-flex flex-column m-auto justify-content-center"
                    style={{
                      background: day.color,
                      borderRadius: "100%",
                      width: "40px",
                      height: "40px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="d-flex flex-column m-auto justify-content-center"
                      style={{ width: "25px", height: "25px" }}
                    >
                      {day.icon}
                    </div>
                  </div>
                  <div
                    className="mx-1 w-100"
                    style={{
                      fontSize: "calc(0.6rem + 0.5vmin)",
                      textAlign: "left",
                      lineHeight: "100%",
                    }}
                  >
                    {day.name}
                  </div>
                </div>
              ))}
              {/* out */}
            </div>
          </div>

          <div
            className="btnAction_pruebas letraspopfi2"
            style={{ width: "70%" }}
          >
            <ButtonMob
              handleClick={() => setModalInfo(null)}
              colorButton={"#9EACED"}
              text="Aceptar"
              className={
                laptop575.matches
                  ? "mb-3 mt-3 letraspopfi2actualizar"
                  : " letraspopfi2actualizar py-2"
              }
            />
          </div>
        </div>
      </div>
    );
  }
  //mobile
  function programaTrainingMobile() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 9999999,
      whiteSpace: "pre-line",
    };

    setModalInfo(
      laptop575.matches ? null : (
        <div
          className="modalInfo justify-content-center align-items-center"
          style={styleModal}
        >
          <div
            className="modalInfoContenido"
            style={{ width: "calc(65rem + 2vmin)", maxWidth: "100%" }}
          >
            <div
              className=""
              style={{
                position: "absolute",
                top: "5%",
                right: "5%",
                fontWeight: "bold",
                fontSize: "1.5rem",
                color: " #9DACBD",
                cursor: "pointer",
              }}
              onClick={() => setModalInfo(null)}
            >
              <i className="bi bi-x-circle"></i>
            </div>

            <div
              className="title mb-3 py-1 col-7 fw-light"
              style={{
                lineHeight: "100%",
                textAlign: "left",
                fontSize: "1.3rem",
              }}
            >
              <strong>Programa</strong> de entrenamiento
            </div>
            <div className="col-12 d-flex flex-column">
              <SwiperPlan
                userData={userData}
                mesPrograma={mesPrograma}
                CardioTraining={(e) => CardioTrainingMobile(e)}
                anchoGrid={"100%"}
              />

              <PerfectScrollbar style={{ height: "10rem" }}>
                <div
                  className="mx-auto"
                  style={{
                    padding: "0 3%",
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(2, 1fr)" /* Divide los elementos en dos columnas */,
                  }}
                >
                  {/* ini */}
                  {mestraining.map((day) => (
                    <div
                      className="d-flex my-2"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        className="col-3 d-flex flex-column m-auto justify-content-center"
                        style={{
                          background: day.color,
                          borderRadius: "100%",
                          width: "40px",
                          height: "40px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="d-flex flex-column m-auto justify-content-center"
                          style={{ width: "25px", height: "25px" }}
                        >
                          {day.icon}
                        </div>
                      </div>
                      <div
                        className="mx-1 w-100"
                        style={{
                          fontSize: "calc(0.6rem + 0.5vmin)",
                          textAlign: "left",
                          lineHeight: "100%",
                        }}
                      >
                        {day.name}
                      </div>
                    </div>
                  ))}
                  {/* out */}
                </div>
              </PerfectScrollbar>
            </div>

            <div
              className="btnAction_pruebas letraspopfi2"
              style={{ width: "100%" }}
            >
              <ButtonMob
                handleClick={() => setModalInfo(null)}
                colorButton={"#9EACED"}
                text="Aceptar"
                className={
                  laptop575.matches
                    ? "mb-3 mt-3 letraspopfi2actualizar"
                    : " letraspopfi2actualizar py-2"
                }
              />
            </div>
          </div>
        </div>
      )
    );
  }

  function CardioTrainingMobile(elemento) {
    let e = elemento[0];

    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 99999999,
      whiteSpace: "pre-line",
    };
    setModalInfoCardio(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{ maxWidth: "100%", width: "calc(100vw - 10%)" }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfoCardio(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div
            className="title col-12 mb-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Programa de Cardio / Mes {whatMonth(elemento[1])}
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              Día plan entrenamiento: {elemento[1]}
            </button>
            <button
              className="mx-auto d-flex relative"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.label.replace("Cardio", "")}
            </button>
          </div>
          <div
            className="d-flex m-auto  "
            style={{
              background: "#F4F0ED",
              borderRadius: "40px",
              padding: "5% 2%",
              minWidth: "20rem",
            }}
          >
            <div className="m-auto">
              <div
                className="col-3 d-flex flex-column m-auto justify-content-center"
                style={{
                  background: "var(--elevva)",
                  borderRadius: "100%",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex flex-column m-auto justify-content-center"
                  style={{ width: "25px", height: "25px" }}
                >
                  <IconCaminar />
                </div>
              </div>
            </div>

            <div
              className="d-flex flex-column m-auto "
              style={{ textAlign: "left" }}
            >
              <div className="fw-bold">{e.detail.type}</div>
              <div className="m-auto d-flex " style={{ alignItems: "center" }}>
                <IconClock />
                <span className="ms-1"> {e.detail.duration} min</span>
              </div>
            </div>
            <div
              className="d-flex flex-column m-auto"
              style={{ textAlign: "left" }}
            >
              <div className="my-auto d-flex " style={{ alignItems: "center" }}>
                <IconIntensity />
                <span className="ms-1"> Intensidad {e.detail.intensity}</span>
              </div>
              <div
                className="my-auto mx-1 d-flex "
                style={{ alignItems: "center" }}
              >
                <IconCaminata />
                <span className="ms-1"> {e.detail.type}</span>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Metodología
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.method}
            </button>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Objetivo
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.target}
            </button>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <button
              className="mx-auto d-flex relative col-6"
              style={{
                color: "#394F65",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "start",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "#F4F0ED",
                borderRadius: "20px",
                border: "1px solid #F4F0ED",
              }}
            >
              Zona de frecuencia cardiaca
            </button>
            <button
              className="mx-auto d-flex relative col-5"
              style={{
                color: "var(--white)",
                fontSize: "calc(0.6rem + 0.5vmin)",
                padding: "2% 5%",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                verticalAlign: "middle",
                background: "var(--elevva)",
                borderRadius: "20px",
                border: "1px solid var(--elevva)",
              }}
            >
              {e.detail.hr_zone}
            </button>
          </div>
          <div
            className="title col-12 my-2"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            Observaciones
          </div>
          <PerfectScrollbar
            className="title col-12 my-2 mx-auto"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              maxWidth: "20rem",
            }}
          >
            <span style={{ fontSize: "0.8rem", color: "#9DACBD" }}>
              {e.detail.notes}{" "}
            </span>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }

  // timer
  const updateCount = () => {
    if (
      (stepIndex == 3 && window.innerWidth > 768) ||
      (stepIndex == 5 && window.innerWidth < 768)
    ) {
      timer =
        !timer &&
        setInterval(() => {
          if (tensionArterial == "120") {
            setTensionArterial(0);
            setTensionArterial1(0);
          } else {
            setTensionArterial("120");
            setTensionArterial1("80");
          }
        }, 1000);
    } else {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    updateCount(stepIndex);

    return () => clearInterval(timer);
  }, [tensionArterial, stepIndex]);

  // graphics
  const updateGrapichs = () => {
    if (stepIndex == 6) {
      graphics =
        !graphics &&
        setInterval(() => {
          if (counter == 0) {
            // setcounter(50)
            if (Number(counterStorage) > 0) {
              setcounter(Number(counterStorage));
            } else {
              setcounter(50);
            }
            setData([
              {
                id: "Calorias",
                data: [
                  { x: "2023-05-01", y: 0 },
                  { x: "2023-05-02", y: 20 },
                  { x: "2023-05-05", y: 60 },
                  { x: "2023-05-07", y: 60 },
                  { x: "2023-05-10", y: 20 },
                ],
              },
            ]);
          } else {
            setcounter(0);

            setData([
              {
                id: "Calorias",
                data: [
                  { x: "2023-05-01", y: 0 },
                  { x: "2023-05-02", y: 0 },
                  { x: "2023-05-05", y: 0 },
                  { x: "2023-05-07", y: 0 },
                  { x: "2023-05-10", y: 0 },
                ],
              },
            ]);
          }
        }, 1000);
    } else {
      clearInterval(graphics);
    }
  };

  // function fetchData() {
  //   function type_14 (){ return  instance.get(`customers/healthlog/?type__in=14`).then((res) => {
  //     const daysselected = res.data;
  //     if (daysselected.length > 0) {

  //       return daysselected
  //     }
  //   });
  // }
  // function type_15 (){ return  instance.get(`customers/healthlog/?type__in=15`).then((res) => {
  //   const daysselected = res.data;
  //   if (daysselected.length > 0) {

  //     return daysselected
  //   }
  // });
  // }
  //   Promise.all([type_14(),type_15()])
  //   .then((response) => {
  //     let [type_14_res,type_15_res] = response;
  //     return [].concat(type_14_res).concat(type_15_res);
  //   })
  //   .then((totalreq) => {

  //    let value1=totalreq[0] && totalreq.filter(e=>e.type ==14).length>0 ?totalreq.filter(e=>e.type ==14)[0].numeric_value:0;
  //    let value2=totalreq[1] && totalreq.filter(e=>e.type ==15).length>0 ?totalreq.filter(e=>e.type ==15)[0].numeric_value:0;
  //    if(totalreq && totalreq.length>0){
  //     setTensionArterial(value1);
  //     setTensionArterial1(value2)
  //    }
  //   })
  //   .catch((err) => console.log(err));
  // }

  function referPlan() {
    if (localStorage.membership) {
      instance
        .get("memberships/summary/")
        .then(async (res) => {
          console.log("resssssssssssssssssss", res.data);
          setModalInfo(
            <ModalInfoPlan
              handleCancel={hideModal}
              idMembership={localStorage.membership}
              infoData={res.data}
            />
          );
        })
        .catch((err) => {
          if (err.response.status === 403) {
            avisoBloqueo();
          }
        });
    } else {
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={hideModal}
          textButtonConfirmar={"Entendido"}
          classcorrecta={"recuerdaTitle"}
          handleCancellcargue={hideModal}
          title="Lo sentimos"
          text={`Aún no tienes una mebresía activa`}
          classesMsg="colorText_Modal"
        />
      );
    }
  }
  function conditionPlan() {
    if (userData.customer.had_valoration) {
      BlockActivity ? avisoBloqueo() : navigate(`/actividad`);
      //   alert('In process')
    } else {
      return setModalInfo(
        <ModalInfo
          error="none"
          textButtonAction="Agendar"
          handleCancellcargue={hideModal}
          handleReservar={() => {
            const trainingValoration =
              trainings &&
              trainings.filter((training) => training.is_valoration == true);
            const idValoration = trainingValoration[0].id;
            if (userData.customer.check_booking_tutorial) {
              navigate(`/training/${idValoration}`);
            } else {
              var formData1 = new FormData();
              formData1.append("check_booking_tutorial", true);
              instance
                .patch(`customers/`, formData1)
                .then((res) => {
                  if (res.data) {
                    if (laptop575.matches) {
                      navigate(`/TutorialReserva/${idValoration}`);
                    } else {
                      navigate(`/TutorialResponsive/${idValoration}`);
                    }
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
          }}
          iconoWidth={"1.5rem"}
          textSizeUp={"1.3rem"}
          icono={iconoFlechaElevva}
          text={`Para poder acceder <br/> a tu experiencia  de  <br/> bienestar,  <br/> debes agendar tu  <br/> clase de valoración`}
        />
      );
    }
  }
  useEffect(() => {
    if (userData) {
      const progressTrainig = userData?.membership?.current_plan_day;
      const arrayPlan = mesPrograma.length;
      const totalPlan = (Number(progressTrainig) * 100) / Number(arrayPlan + 1);
      if (
        typeof totalPlan == "number" &&
        progressTrainig > 1 &&
        arrayPlan > 0
      ) {
        setcounter(Math.round(totalPlan));
        setcounterStorage(Math.round(totalPlan));
      } else {
        setcounterStorage(0);
        setcounter(0);
      }
    }
  }, [userData, mesPrograma]);

  function nextTraining(e) {
    if (e == "valoracion_fisica") {
      modalAvance(preguntasAvanceId[0]);
    } else if (e == "valoracion_nutricional") {
      modalAvance(preguntasAvanceId[1]);
    } else if (e == "cardio") {
      modalAvance(preguntasAvanceId[2]);
    } else if (e == "reserva") {
      modalAvance(preguntasAvanceId[3]);
    } else if (e == "grupal") {
      modalAvance(preguntasAvanceId[4]);
    } else if (e == "video") {
      modalAvance(preguntasAvanceId[5]);
    } else {
      modalAvance(preguntasAvanceId[6]);
    }
  }

  function postAvanzar() {
    instance
      .post(`memberships/training_plan/confirm/`)
      .then((res) => {
        if (res.data) {
          setplanDaySelected(res.data.current_plan_day - 1);
          hideModal();
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log("err", err);
          return setModalInfo(
            <ModalInfo
              error="false"
              handleClick={hideModal}
              textButtonConfirmar={"Entendido"}
              classcorrecta={"recuerdaTitle"}
              handleCancellcargue={hideModal}
              title="Hay un error inesperado"
              text={`Hubo un error <br/> inténtalo de nuevo <br/> más tarde.`}
              classesMsg="colorText_Modal"
            />
          );
        }
      });
  }

  function updateTension(e) {
    e.preventDefault();
    let valorTension1 = e.target[0].value.trim();
    let valorTension2 = e.target[1].value.trim();

    let date = Moment().format("YYYY-MM-DD hh:mm:ss");

    if (valorTension1 && valorTension2) {
      instance
        .post(`customers/healthlog/new/multiple/`, [
          { part: 10, numeric_value: valorTension1, date: date, type: 14 },
          { part: 10, date: date, numeric_value: valorTension2, type: 15 },
        ])
        .then((res) => {
          if (res.data) {
            updateMetrics();

            return setModalInfo(
              <ModalInfo
                error="none"
                textButtonAction="Entendido"
                handleCancellcargue={() => hideModalTension(e)}
                handleReservar={() => hideModalTension(e)}
                iconoWidth={"2rem"}
                textSizeUp={"1.2rem"}
                icono={icoPositivo}
                text={`Tu presión arterial  <br/> ha sido actualizada  <br/> con éxito`}
              />
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            avisoBloqueo();
          } else {
            return setModalInfo(
              <ModalInfo
                error="false"
                handleClick={hideModal}
                textButtonConfirmar={"Entendido"}
                classcorrecta={"recuerdaTitle"}
                handleCancellcargue={hideModal}
                title="Hay un error inesperado"
                text={`No pudimos registrar tu presión <br/> arterial, inténtalo de nuevo <br/> más tarde.`}
                classesMsg="colorText_Modal"
              />
            );
          }
        });
    } else {
      setModalInfo(
        <ModalInfo
          error="false"
          handleClick={hideModal}
          textButtonConfirmar={"Entendido"}
          classcorrecta={"recuerdaTitle"}
          handleCancellcargue={hideModal}
          title="Hay un error inesperado"
          text={`Debes ingresar<br/>ambos valores.`}
          classesMsg="colorText_Modal"
        />
      );
    }
  }
  function colorType(type) {
    if (type == "99") {
      return "var(--pointsGraficaHr)";
    } else if (type == "14") {
      return "var(--pointsGraficaTension)";
    } else if (type == "distance") {
      return "var(--pointsGraficaDistance)";
    } else return "var(--pointsGrafica)";
  }

  useEffect(() => {
    updateGrapichs(stepIndex);

    return () => {
      clearInterval(graphics);
    };
  }, [counter, stepIndex]);

  useEffect(() => {
    if (tutorialCheck) {
      setcorrer(false);
    } else {
      setcorrer(true);
    }
  }, [tutorialCheck]);

  // //  useEffect(() => {
  // //   if(correr ==false && counterStorage !=null)
  // // {
  // //   setcounter(Number(counterStorage));
  // //   if (number) number.innerHTML = 0 + "%";
  // //   rootr.style.setProperty(
  // //     "--percentageCircularBar",
  // //    -290 + "%"
  // //   );
  // // }

  //  }, [correr])

  useEffect(() => {
    let idNavResponsive = document.getElementById("navMobile");

    if (idNavResponsive && stepIndex > 0) {
      idNavResponsive.classList.add(`addZindex`);
    } else {
      if (idNavResponsive) {
        idNavResponsive.classList.remove(`addZindex`);
      }
    }

    if (stepIndex > 2 && stepIndex < 4 && tutorialCheck == false) {
      var formData1 = new FormData();
      formData1.append("check_main_tutorial", true);
      instance
        .patch(`customers/`, formData1)
        .then((res) => {
          setcorrer(res.data.check_main_tutorial);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    setheightjoyride(false);
    if (stepIndex == 0) {
      setTensionArterial(0);
      setTensionArterial1(0);
      setarterial_prom("---/--");
      setcardiaca_prom("---");
      setrecorrida_prom("---");
      setcalorias_prom("---");
      rangeChallenger(Range, Type, frecuencyUp);
      setcounter(Number(counterStorage));
    }
  }, [stepIndex]);

  useEffect(() => {
    let frequency = resolveFrec(Range, frecuencyUp);

    if (Type == "99" || Type == "14") {
      rangeChallenger(Range, Type, frequency);
    } else {
      rangeDisciplines(Range, Type, frequency);
    }
  }, [Range, frecuencyUp]);

  const moment = extendMoment(Moment);

  require("moment-timezone");
  const handleJoyrideCallback = (data) => {
    const { status, type, action, index } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setstepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setcorrer(false);
      setstepIndex(0);
    }
  };

  const [alllevels, setalllevels] = useState(null);
  useEffect(() => {
    const levels = [];
    const getData = async () => {
      await instance.get(`customers/level/`).then(async (res) => {
        const daysselected = res.data;

        if (daysselected) {
          for (const i of daysselected) {
            const identrenamiento = i.training;
            const lev = i.level;
            const uuid = i.id;
            const sublev = i.sublevel;
            const current_sesio = i.current_session;
            await instance.get(`trainings/${identrenamiento}`).then((res) => {
              let info = res.data;
              if (info && info.group == false) {
                levels[identrenamiento] = {
                  id: identrenamiento,
                  uuid: uuid,
                  tiene_valoration: info.is_valoration,
                  titulo: info.name.toLowerCase(),
                  descripcion: info.description,
                  level: lev,
                  subnivel: sublev,
                  current_session: current_sesio - 1,
                };
              }
            });
          }

          //
        }
      });

      setalllevels(levels.filter((e) => e && e.tiene_valoration == false));
    };
    getData();
  }, []);
  const yAxisFormat = (date) => {
    if (data.length == 1) {
      return Math.round(date);
    } else {
      return date;
    }
  };

  function formatMin() {
    if (data[0] && data[0].data.length === 1 && data.length == 1) {
      return "auto";
    } else return 0;
  }
  function updateMetrics() {
    instance
      .get("customers/metrics/summary/")
      .then((response) => {
        const summary = response.data;

        if (summary) {
          const valueSteps = Math.round(summary.distance * 1.31233595800525);

          const valorDistance = valueSteps
            .toLocaleString("en-US", { minimumFractionDigits: 0 })
            .replace(",", ".");
          const valorKcal = summary?.kcal
            .toLocaleString("en-US", { minimumFractionDigits: 0 })
            .replace(",", ".");

          setcalorias_prom(valorKcal || "--");
          setrecorrida_prom(valorDistance || "--");
          setcardiaca_prom(Math.round(summary.hr) || "--");
          if (summary.blood_pressure) {
            let pressureS = Math.round(summary.blood_pressure.s);
            let pressureD = Math.round(summary.blood_pressure.d);
            if (pressureS > 0) {
              if (pressureS > 999) {
                pressureS =
                  summary.blood_pressure.s.toString().slice(0, 3) + "...";
              }
              if (pressureD > 999) {
                pressureD =
                  summary.blood_pressure.d.toString().slice(0, 3) + "...";
              }
              setarterial_prom(pressureS + "/" + pressureD);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const preguntasAvanceId = [
    {
      question: "¿Ya completaste tu actividad valoración física - control?",
      icon: <ValoraIco width={"100%"} height={"100%"} />,
      color: "#70AAD8",
    },
    {
      question:
        "¿Ya completaste tu actividad valoración nutricional - control?",
      icon: <ValoraNutricional width={"100%"} height={"100%"} />,
      color: "#70AAD8",
    },
    {
      question: "¿Ya completaste tu actividad plan de cardio?",
      icon: <CardioIcon width={"100%"} height={"100%"} />,
      color: "#A43E80",
    },
    {
      question: "¿Ya completaste tu actividad entrenamiento 1 a 1?",
      icon: <TrainIcon width={"100%"} height={"100%"} />,
      color: "#8089CC",
    },

    {
      question: "¿Ya completaste tu actividad clase grupal?",
      icon: <GrupalIcon width={"100%"} height={"100%"} />,
      color: "#F3539B",
    },
    {
      question: "¿Ya completaste tu actividad video pregrabado?",
      icon: <VideoIcon width={"100%"} height={"100%"} />,
      color: "#4B6380",
    },
    {
      question: "¿Ya completaste tu actividad día de descanso?",
      icon: <ReposoIcon width={"100%"} height={"100%"} />,
      color: "#CBA791",
    },
  ];

  function modalAvance(preguntasAvance) {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModalInfo(
      <div
        className="modalInfo justify-content-center align-items-center"
        style={styleModal}
      >
        <div className="modalInfoContenido" style={{ maxWidth: "100%" }}>
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModalInfo(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>

          <div>
            <div
              className="col-3 d-flex flex-column m-auto justify-content-center"
              style={{
                background: preguntasAvance.color,
                borderRadius: "100%",
                width: "40px",
                height: "40px",
                alignItems: "center",
              }}
            >
              <div
                className="d-flex flex-column m-auto justify-content-center"
                style={{ width: "25px", height: "25px" }}
              >
                {preguntasAvance.icon}
              </div>
            </div>
          </div>
          <div
            className="title col-9 mx-auto my-2"
            style={{
              lineHeight: "100%",
              textAlign: "center",
              fontSize: "1rem",
            }}
          >
            {preguntasAvance.question}
          </div>
          <div className="col-12 d-flex flex-column mx-auto">
            <ButtonMob
              text="Confirmar"
              className="my-0 py-1"
              colorButton={"#D74688"}
              borderbutton={`1px solid #D74688`}
              handleClick={postAvanzar}
            />
            <ButtonMob
              colorButton={"#fff"}
              borderbutton={`1px solid #D74688`}
              colorTextButton={`#D74688`}
              text="Aún no"
              className=" py-1"
              handleClick={hideModal}
            />
          </div>
        </div>
      </div>
    );
  }
  useEffect(() => {
    updateMetrics();
    instance
      .get("users/current/")
      .then((response) => {
        console.log(response.data.membership);
        const customer = response.data.customer;
        setTutorialCheck(customer.check_main_tutorial);
        if (response.data.membership) {
          if (response.data.membership.current_plan_day > 0) {
            setplanDaySelected(response.data.membership.current_plan_day - 1);
          } else {
            setplanDaySelected(response.data.membership.current_plan_day);
          }
          instance
            .get(`memberships/training_plan`)
            .then((response) => {
              let dataRes = response.data;
              console.log(dataRes);
              if (
                dataRes.personal_training_plan &&
                JSON.parse(dataRes.personal_training_plan) > 0
              ) {
                setmesPrograma(JSON.parse(dataRes.personal_training_plan));
              } else {
                setmesPrograma(dataRes.training_plan);
              }
            })
            .catch((error) => {
              if (error.response.status === 403) {
                setBlockTrainingPlan(true);
              }
            });
          // if (
          //   response.data.membership.personal_training_plan &&
          //   JSON.parse(response.data.membership.personal_training_plan) > 0
          // ) {
          //   setmesPrograma(
          //     JSON.parse(response.data.membership.personal_training_plan)
          //   );
          // } else {
          //   setmesPrograma(response.data.membership.training_plan);
          // }
        }
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    instance
      .get(`${NuevaAxiosURL}trainings`)
      .then((response) => {
        setTrainings(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // fetchData();
  }, [location.pathname]);

  async function rangeChallenger(Ranger, type, frequency) {
    await instance
      .get(
        `customers/metrics/healthlog/data/?range=${Ranger}&type=${type}&frequency=${frequency}`
      )
      .then(async (response) => {
        const summary = response.data;
        if (summary) {
          let dateConvert = [{ id: "", data: [] }];
          dateConvert[0]["id"] = type == 99 ? "Hr" : "Tensión sistólica";
          summary[String(type)].map((e, index) => {
            dateConvert[0]["data"].push({
              x: e.date,
              y: e.value,
            });
          });

          if (type == 14) {
            await instance
              .get(
                `customers/metrics/healthlog/data/?range=${Ranger}&type=${15}&frequency=${frequency}`
              )
              .then((response) => {
                const summaryDiastole = response.data;
                if (summaryDiastole) {
                  let dateConvertDiastole = [{ id: "", data: [] }];
                  dateConvertDiastole[0]["id"] = "Tensión diastólica";
                  summaryDiastole[String(15)].map((e, index) => {
                    dateConvertDiastole[0]["data"].push({
                      x: e.date,
                      y: e.value,
                    });
                  });

                  dateConvert.push(dateConvertDiastole[0]);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          setData([]);

          setData(dateConvert);
          if (dateConvert[0].data.length > 0) {
            let yMax = Math.max(...dateConvert[0].data.map((entry) => entry.y));

            let yMin = Math.min(...dateConvert[0].data.map((entry) => entry.y));
            setyMax(yMax);
            setyMin(yMin);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setBlockActivity(true);
        }
      });
  }

  function rangeDisciplines(Ranger, type, frequency) {
    instance
      .get(
        `customers/metrics/disciplines/data/?range=${Ranger}&type=${type}&frequency=${frequency}`
      )
      .then((response) => {
        const summary = response.data;
        if (summary) {
          let dateConvert = [{ id: "", data: [] }];
          dateConvert[0]["id"] = type == "kcal" ? "kcal" : "distance";
          summary[String(type)].map((e, index) => {
            dateConvert[0]["data"].push({
              x: e.date,
              y: e.value,
            });
          });
          setData([]);
          setData(dateConvert);

          if (dateConvert[0].data.length > 0) {
            let yMax = Math.max(...dateConvert[0].data.map((entry) => entry.y));

            let yMin = Math.min(...dateConvert[0].data.map((entry) => entry.y));
            setyMax(yMax);
            setyMin(yMin);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setBlockActivity(true);
        }
      });
  }

  function changeRange(e) {
    let frequency = resolveFrec(e.target.value, frecuencyUp);
    if (Type == "99" || Type == "14") {
      rangeChallenger(e.target.value, Type, frequency);
    } else {
      rangeDisciplines(Range, e.target.value, frequency);
    }

    setRange(e.target.value);
  }
  function resolveFrec(Ranger, frequency) {
    if (Ranger == "cw" || Ranger == "cm" || Ranger == "lm") {
      if (frequency == "day") {
        return "day";
      } else return "hour";
    } else return "month";
  }
  function changeType(e) {
    let frequency = resolveFrec(Range, frecuencyUp);
    if (e.target.value == "99" || e.target.value == "14") {
      rangeChallenger(Range, e.target.value, frequency);
    } else {
      rangeDisciplines(Range, e.target.value, frequency);
    }
    setType(e.target.value);
  }
  const pathHere = props.path;

  const navigate = useNavigate();

  const theme1 = {
    axis: {
      fontSize: "3px",

      domain: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
      },
      ticks: {
        line: {
          stroke: "#ffff",
          strokeWidth: 0.5,
        },
        text: {
          fill: "var(--elevvaArrowColor)",
        },
      },
      legend: {
        text: {
          fontSize: "3vw",

          fontWeight: "700",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--backgroundGraficGrid)",

        strokeWidth: 1,
      },
    },
  };

  useEffect(() => {
    const copyEntrenamientos = [];
    const copyDisciplines = [];
    const getData = async () => {
      await instance.get("customers/disciplines/").then(async (res) => {
        const mor = res.data;
        if (mor) {
          for (const i of mor) {
            const idDiscipline = i.discipline.id;
            const UUid = i.id;
            const kcal = i.kcal;
            const data = i.date;
            const hr_max = i.hr_max;
            const hr_med = i.hr_med;
            const status = i.status;
            copyDisciplines[UUid] = {
              id: "d" + idDiscipline,
              UUid: UUid,
              data: data,
              status: i.discipline.active,
              titulo: i.discipline.name.toLowerCase(),
              descripcion: i.discipline.summary,
              icon: i.discipline.icon,
              numeric_value: hr_med,
              duracion: i.duration,
              calorias: kcal,
            };
          }
          // console.log(copyDisciplines);
        }
      });

      await instance
        .get("booking/?any")
        .then(async (res) => {
          const data = res.data.filter(
            (e) =>
              e.status > 30 &&
              e.status != 70 &&
              e.status != 90 &&
              e.status != 50 &&
              e.date < moment().format()
          );

          if (data) {
            for (const i of data.filter((e) => e.training)) {
              const name = i.training.name;

              const icon = i.training.icon;
              const UUid = i.id;
              const dataT = i.date;

              const img = i.training.cover_picture; //.match(/(img[\d]*b.png)/)[0]

              if (data != null) {
                copyEntrenamientos[UUid] = {
                  id: UUid,
                  titulo: name.toLowerCase(),
                  img: img,
                  UUid: UUid,
                  data: dataT,
                  icon: icon,
                };
              }
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setBlockSessions(true);
          }
        });
      let totalSesions = copyEntrenamientos
        .filter((e) => e)
        .concat(copyDisciplines.filter((e) => e));
      setCopyAllEntrenamientos(totalSesions.sort(useCompareDate)[0]);
    };

    getData();
  }, []);

  const hideModal = () => {
    setModalInfo(null);
  };
  const hideModalTension = (event) => {
    event.target.reset();
    setModalInfo(null);
  };
  useEffect(() => {
    setModalInfo(
      <ModalInfoMembresias
        handleCancellcargue={() => setModalInfo(null)}
        error="tru"
        text={
          "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
        }
        classesMsg=""
      />
    );
  }, [pathHere]);
  const avisos = {
    aviso_one: [
      "Adquiere todos los beneficios de ser elevva+. Selecciona tu plan ahora y eleva tu estilo de vida",
      "Inicia tu prueba gratuita de 7 dias.",
      "var(--barraPublicidad)",
    ],
    aviso_two: [
      "Mejora tu plan y disfruta de muchos más beneficios con elevva+",
      "Mejorar tu plan.",
      "var(--elevvaTextTitle)",
    ],
    aviso_three: [
      "Tu prueba gratuita ha expirado. Selecciona un plan y sigue disfrutando elevva+. ",
      "Activar plan.",
      "var(--elevva)",
    ],
  };
  useEffect(() => {
    const totalallevel = alllevels ? alllevels.length : 0;
    const unidadtotal = alllevels
      ? alllevels.map((e) => e.current_session).reduce((a, b) => a + b, 0)
      : 0;

    const now =
      totalallevel == 0
        ? 0
        : Math.round(((unidadtotal / totalallevel) * 100) / 120);
    setProgressLevel(now);
  }, [alllevels]);

  const [barraPublicidad, setbarraPublicidad] = useState(true);
  const [clasesReservadas, setclasesReservadas] = useState(null);
  const [ModalRestrictBook, setModalRestrictBook] = useState(false);
  // const [avisosvarios, setavisosvarios] = useState(avisos[`aviso_two`]);
  const [avisosvarios, setavisosvarios] = useState(avisos[`a`]); // avisoa
  const [modalInfo, setModalInfo] = useState(null);

  const [dayselected, setdayselected] = useState({
    lunes: false,
    martes: false,
    miércoles: false,
    jueves: false,
    viernes: false,
    sábado: false,
    domingo: false,
  });

  useEffect(() => {
    const inicioday = moment().format().slice(0, 11) + "00:00";

    const finislongdays =
      moment().add(30, "days").format().slice(0, 11) + "00:00";
    instance
      .get(
        `booking/?available&date__lte=${finislongdays}&date__gte=${inicioday}`
      )
      .then((res) => {
        let clases = res.data;

        if (clases) {
          setclasesReservadas(clases);
        }
      })
      .catch((err) => {
        if (err.response.status == 403) {
          setModalRestrictBook(true);
        }
      });

    instance
      .get(`customers/healthlog/?part=20`)
      .then((res) => {
        const daysselected = res.data;
        // console.log(daysselected);
        if (daysselected.length > 0) {
          const grasabrazo_iz = daysselected.filter((e) => e.type == 3);
          const masabrazo_iz = daysselected.filter((e) => e.type == 10);
          // console.log(masabrazo_iz);
          setbrazoiz(
            masabrazo_iz.length == 1
              ? masabrazo_iz[0].numeric_value
              : masabrazo_iz.length > 1
              ? darsigno(
                  masabrazo_iz[0].numeric_value,
                  masabrazo_iz[1].numeric_value
                )
              : 0
          );
          setbrazoizgrasa(
            grasabrazo_iz.length == 1
              ? grasabrazo_iz[0].numeric_value
              : grasabrazo_iz.length > 1
              ? darsigno(
                  grasabrazo_iz[0].numeric_value,
                  grasabrazo_iz[1].numeric_value
                )
              : 0
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setBlockBody(true);
        }
      });
    instance.get(`customers/healthlog/?part=30`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        // console.log(daysselected);
        let grasabrazo_de = daysselected.filter((e) => e.type == 3);
        let masabrazo_de = daysselected.filter((e) => e.type == 10);
        // console.log(grasabrazo_de, masabrazo_de);
        setbrazode(
          masabrazo_de.length == 1
            ? masabrazo_de[0].numeric_value
            : masabrazo_de.length > 1
            ? darsigno(
                masabrazo_de[0].numeric_value,
                masabrazo_de[1].numeric_value
              )
            : 0
        );
        setbrazodegrasa(
          grasabrazo_de.length == 1
            ? grasabrazo_de[0].numeric_value
            : grasabrazo_de.length > 1
            ? darsigno(
                grasabrazo_de[0].numeric_value,
                grasabrazo_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=40`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_iz = daysselected.filter((e) => e.type == 3);
        const masapierna_iz = daysselected.filter((e) => e.type == 10);

        setpiernaiz(
          masapierna_iz.length == 1
            ? masapierna_iz[0].numeric_value
            : masapierna_iz.length > 1
            ? darsigno(
                masapierna_iz[0].numeric_value,
                masapierna_iz[1].numeric_value
              )
            : 0
        );
        setpiernaizgrasa(
          grasapierna_iz.length == 1
            ? grasapierna_iz[0].numeric_value
            : grasapierna_iz.length > 1
            ? darsigno(
                grasapierna_iz[0].numeric_value,
                grasapierna_iz[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=50`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasapierna_de = daysselected.filter((e) => e.type == 3);
        const masapierna_de = daysselected.filter((e) => e.type == 10);

        setpiernade(
          masapierna_de.length == 1
            ? masapierna_de[0].numeric_value
            : masapierna_de.length > 1
            ? darsigno(
                masapierna_de[0].numeric_value,
                masapierna_de[1].numeric_value
              )
            : 0
        );
        setpiernadegrasa(
          grasapierna_de.length == 1
            ? grasapierna_de[0].numeric_value
            : grasapierna_de.length > 1
            ? darsigno(
                grasapierna_de[0].numeric_value,
                grasapierna_de[1].numeric_value
              )
            : 0
        );
      }
    });
    instance.get(`customers/healthlog/?part=60`).then((res) => {
      const daysselected = res.data;
      if (daysselected.length > 0) {
        const grasaabdomen = daysselected.filter((e) => e.type == 3);
        const masaabdomen = daysselected.filter((e) => e.type == 10);

        setabdomen(
          masaabdomen.length == 1
            ? masaabdomen[0].numeric_value
            : masaabdomen.length > 1
            ? darsigno(
                masaabdomen[0].numeric_value,
                masaabdomen[1].numeric_value
              )
            : 0
        );
        setabdomengrasa(
          grasaabdomen.length == 1
            ? grasaabdomen[0].numeric_value
            : grasaabdomen.length > 1
            ? darsigno(
                grasaabdomen[0].numeric_value,
                grasaabdomen[1].numeric_value
              )
            : 0
        );
      }
    });
    instance
      .get(`customers/metrics/weekly/`)
      .then((response) => {
        if (response.data) {
          let currentWeek = response.data.current.week_days;

          setdayselected({
            lunes: currentWeek[0] == 0 ? false : true,
            martes: currentWeek[1] == 0 ? false : true,
            miércoles: currentWeek[2] == 0 ? false : true,
            jueves: currentWeek[3] == 0 ? false : true,
            viernes: currentWeek[4] == 0 ? false : true,
            sábado: currentWeek[5] == 0 ? false : true,
            domingo: currentWeek[6] == 0 ? false : true,
          });
        }
      })
      .catch((err) => console.log(err.response));
  }, []);

  useEffect(() => {
    const rootr = document.documentElement;

    let intervalIdr;
    if (counter) {
      var count = 0;

      rootr.style.setProperty(
        "--percentageCircularBar",
        300 - (300 * counter) / 100 + "%"
      );
      intervalIdr = setInterval(
        () => {
          if (count == counter) {
            clearInterval(intervalIdr);
          } else {
            count += 1;
          }
        },

        30
      );
    } else {
      rootr.style.setProperty("--percentageCircularBar", -290 + "%");
    }
  }, [counter]);

  const TrainingEstadistics = [
    [
      "Tensión <br/> arterial promedio",
      process.env.PUBLIC_URL + "/assets/img/tensionarterial3D.svg",
      "var(--colorsesionesTension)",
      "mm Hg",
      arterial_prom,
      "var(--colorsesionesTensionBack)",
    ],
    [
      "Frecuencia cardiaca promedio",
      process.env.PUBLIC_URL + "/assets/img/heart3D.svg",
      "var(--colorsesionesHr)",
      "Ppm",
      cardiaca_prom,
      "var(--elevva)",
    ],
    [
      "Distancia recorrida  <br/>actividad libre",
      process.env.PUBLIC_URL + "/assets/img/distance3D.svg",
      "var(--colorsesionesKm)",
      "Pasos",
      recorrida_prom,
      "var(--elevvabutonLogin_User)",
    ],
    [
      laptop575.matches
        ? "Quema <br/>de calorias"
        : "Quema <br/>de <br/> calorias",
      process.env.PUBLIC_URL + "/assets/img/cal3D.svg",
      "var(--colorsesionesCal)",
      "Kilocalorias",
      calorias_prom,
      "var(--textCal)",
    ],
  ];
  const [isLoading, setIsLoading] = useState(true);

  // Simulando una carga de datos
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  let yTicks = Array.from({ length: 5 }).map((_, index) => {
    const difference = yMax - 0;
    const stepSize = difference / 5;
    let valueid = 0 + stepSize * index;
    let newvalue = yMax - valueid;
    let diferencenew = newvalue / 5;
    let value;

    if (diferencenew >= 1000) {
      // Aproximar a unidades de decenas
      value = Math.ceil(newvalue / 1000) * 1000;
    } else if (diferencenew >= 100) {
      // Aproximar a unidades de centenas
      value = Math.ceil(newvalue / 100) * 100;
    } else if (diferencenew >= 10) {
      // Aproximar a unidades de miles
      value = Math.ceil(newvalue / 10) * 10;
    } else {
      value = Math.ceil(newvalue / 1) * 1;
    }
    // Round the max value to the nearest step

    return { value };
  });

  return (
    <div className="col-12 col-md-10">
      <Joyride
        steps={window.innerWidth < 768 ? stepsMobile : steps}
        run={correr}
        hideCloseButton
        disableScrolling={heightjoyride}
        showSkipButton
        continuous
        stepIndex={stepIndex}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        styles={{
          spotlight: {
            // Set your border-radius here!
            borderRadius: 20,
          },
          options: {
            primaryColor: "#CB3D82",
          },
        }}
        callback={handleJoyrideCallback}
      />

      <PerfectScrollbar>
        {modalInfo}
        {/* desktop */}
        <div
          className="position-relative d-none d-md-block"
          style={{
            background: "var(--backgroundOnboardingContain)",
            height: "100vh",

            padding: "0.5% 4% 1.3% 3%",
          }}
        >
          {avisosvarios ? (
            <div
              className={barraPublicidad ? `d-flex ` : `d-none `}
              style={{
                background: avisosvarios[2],
                color: "var(--white)",
                maxWidth: "70%",
                padding: "5px 7vmin",
                justifyContent: "center",
                flexDirection: "row",
                alignContent: "center",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              <span
                className=" my-auto mx-2"
                style={{
                  fontSize: "1.3vmin",
                }}
              >
                {avisosvarios[0]}{" "}
                <Link
                  className="fw-bold"
                  style={{ color: "var(--white)" }}
                  // to="/ejercicios"
                  to="/Usuario/groupals" //Reserva clases grupales
                >
                  {avisosvarios[1]}
                </Link>
              </span>
              <span
                className="  my-auto"
                style={{
                  fontSize: "1.5vmin",
                  cursor: "pointer",
                }}
                onClick={() => setbarraPublicidad(!barraPublicidad)}
              >
                <i className="bi bi-x-circle"></i>
              </span>
            </div>
          ) : null}
          <div className="d-flex h-100">
            <div className="col-9">
              <div
                style={{
                  height: "100%",
                  padding: "1%",
                  flexDirection: "column",
                }}
              >
                <div
                  className=" d-flex"
                  style={{
                    height: "30%",
                    gap: "1%",
                  }}
                >
                  {laptop575.matches ? (
                    <IngresaTension
                      handleClick={updateTension}
                      tensionArterial={tensionArterial}
                      tensionArterial1={tensionArterial1}
                      widthContainer={"20%"}
                      idJoyride={"tensionArterialPanel"}
                    />
                  ) : null}
                  <PromediosPanel
                    TrainingEstadistics={TrainingEstadistics}
                    idPanel={"promediosPanel"}
                  />
                </div>
                <div
                  className="d-flex"
                  style={{
                    height: "70%",

                    gap: "1%",
                    margin: "1% 0 0 0",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    id={`bodyPanel`}
                    className="position-relative"
                    style={{
                      background: "white",
                      width: "calc(100%/2)",
                      borderRadius: "20px",
                      flexDirection: "column",
                      display: "flex",

                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className=" letraPequeña"
                      style={{
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      Conoce <strong>tu cuerpo</strong>
                      <hr className="m-2" />
                    </span>
                    <div
                      className=" letraPequeña d-flex w-100 "
                      style={{
                        padding: "0 10vmin",
                        justifyContent: "space-evenly",
                        color: "var(--elevvaTextTitle)",
                      }}
                    >
                      <span className="letraBody m-auto">
                        <img
                          alt="bascula"
                          style={{ width: "2.5vmin" }}
                          className="mx-1"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/bascula.svg"
                          }
                        />
                        {/* <strong>Actual:</strong> 78,8 Kg */}
                        <strong>Actual:</strong>{" "}
                        {userData?.customer?.optimal_weight
                          ? userData.customer.weight.toFixed(2) + " Kg"
                          : " ---"}
                      </span>
                      <span className="letraBody m-auto">
                        <img
                          alt="bascula"
                          style={{ width: "2.5vmin" }}
                          className="mx-1"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/bascula.svg"
                          }
                        />{" "}
                        <strong>Objetivo: </strong>
                        <span
                          className="letraBody"
                          style={{ color: "var(--elevva)" }}
                        >
                          {userData?.customer?.optimal_weight
                            ? userData.customer?.optimal_weight.min?.toFixed(
                                2
                              ) +
                              " - " +
                              userData.customer.optimal_weight?.max.toFixed(2) +
                              " Kg"
                            : " ---"}
                        </span>
                      </span>
                    </div>
                    <img
                      alt="FRENTE"
                      className="figurePartsInicio"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/img/onboarding/FRENTE.png"
                      }
                    />
                    <div
                      className="position-absolute "
                      style={{
                        top: "22%",
                        left: "10%",
                        width: "30%",
                        transform: "rotate(-360deg)",
                      }}
                    >
                      <span
                        className="letraBodyInside"
                        style={{ color: "var(--elevvabutonLogin_User)" }}
                      >
                        Brazo derecho
                      </span>
                      <div style={{ lineHeight: "1.5vmin" }}>
                        {" "}
                        <span className="letraBodyInside">
                          {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
                          {Array.isArray(brazodegrasa) ? (
                            brazodegrasa[0] >= 0 ? (
                              <i className="bi bi-caret-up-fill"></i>
                            ) : (
                              <i className="bi bi-caret-down-fill"></i>
                            )
                          ) : null}{" "}
                          Grasa corporal:{" "}
                          {stepIndex == 4
                            ? "5,8%"
                            : Array.isArray(brazodegrasa)
                            ? dejarsolonumber(brazodegrasa[0].toFixed(1)) + "%"
                            : brazodegrasa.toFixed(1) + "%"}
                        </span>
                        <br />
                        <span className="letraBodyInside">
                          {Array.isArray(brazode) ? (
                            brazode[0] >= 0 ? (
                              <i
                                className="bi bi-caret-up-fill"
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                                className="bi bi-caret-down-fill"
                              ></i>
                            )
                          ) : null}{" "}
                          {/* Mm: 37 kg */}
                          Masa muscular:{" "}
                          {stepIndex == 4
                            ? "37 kg"
                            : Array.isArray(brazode)
                            ? dejarsolonumber(brazode[0].toFixed(1)) + "Kg"
                            : brazode.toFixed(1) + "Kg"}
                        </span>
                      </div>
                    </div>
                    <div
                      className="position-absolute"
                      style={{
                        top: "22%",
                        right: "10%",
                        width: "30%",
                        textAlign: "right",
                      }}
                    >
                      <span
                        className="letraBodyInside "
                        style={{ color: "var(--elevvabutonLogin_User)" }}
                      >
                        Brazo izquierdo
                      </span>
                      <div style={{ lineHeight: "1.5vmin" }}>
                        {" "}
                        <span className="letraBodyInside ">
                          {/* Grasa corporal: 5,8% */}
                          Grasa corporal:{" "}
                          {stepIndex == 4
                            ? "5,8%"
                            : Array.isArray(brazoizgrasa)
                            ? dejarsolonumber(brazoizgrasa[0].toFixed(1)) + "%"
                            : brazoizgrasa.toFixed(1) + "%"}
                          {Array.isArray(brazoizgrasa) ? (
                            brazoizgrasa[0] >= 0 ? (
                              <i className="bi bi-caret-up-fill"></i>
                            ) : (
                              <i className="bi bi-caret-down-fill"></i>
                            )
                          ) : null}{" "}
                        </span>
                        <br />
                        <span className="letraBodyInside ">
                          {/* Masa muscular: 37 kg{" "} */}
                          Masa muscular:{" "}
                          {stepIndex == 4
                            ? "37 kg"
                            : Array.isArray(brazoiz)
                            ? dejarsolonumber(brazoiz[0].toFixed(1)) + "Kg"
                            : brazoiz.toFixed(1) + "Kg"}
                          {Array.isArray(brazoiz) ? (
                            brazoiz[0] >= 0 ? (
                              <i
                                className="bi bi-caret-up-fill"
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                                className="bi bi-caret-down-fill"
                              ></i>
                            )
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <span
                      className="position-absolute linebodyInicio"
                      style={{ top: "32%", left: "10%", width: "30%" }}
                    >
                      <hr />
                      <span
                        className="rounded-circle position-absolute"
                        style={{
                          background: "var(--elevvaTexttitleLow)",
                          width: "8px",
                          height: "8px",
                          top: "40%",
                          left: "100%",
                        }}
                      ></span>
                    </span>
                    <div
                      className="position-absolute "
                      style={{
                        top: "48%",
                        left: "10%",
                      }}
                    >
                      <span
                        className="letraBodyInside"
                        style={{ color: "var(--elevvabutonLogin_User)" }}
                      >
                        Abdomen
                      </span>
                      <div style={{ lineHeight: "1.5vmin" }}>
                        {" "}
                        <span className="letraBodyInside">
                          {/* <i className="bi bi-caret-down-fill"></i> Grasa corporal: 5,8% */}
                          {Array.isArray(abdomenpgrasa) ? (
                            abdomenpgrasa[0] >= 0 ? (
                              <i className="bi bi-caret-up-fill"></i>
                            ) : (
                              <i className="bi bi-caret-down-fill"></i>
                            )
                          ) : null}{" "}
                          Grasa corporal:{" "}
                          {stepIndex == 4
                            ? "5,8%"
                            : Array.isArray(abdomenpgrasa)
                            ? dejarsolonumber(abdomenpgrasa[0].toFixed(1)) + "%"
                            : abdomenpgrasa.toFixed(1) + "%"}
                        </span>
                        <br />
                        <span className="letraBodyInside">
                          {Array.isArray(abdomenp) ? (
                            abdomenp[0] >= 0 ? (
                              <i
                                className="bi bi-caret-up-fill"
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                                className="bi bi-caret-down-fill"
                              ></i>
                            )
                          ) : null}{" "}
                          {/* Masa muscular: 37 kg */}
                          Masa muscular:{" "}
                          {stepIndex == 4
                            ? "37 kg"
                            : Array.isArray(abdomenp)
                            ? dejarsolonumber(abdomenp[0].toFixed(1)) + "Kg"
                            : abdomenp.toFixed(1) + "Kg"}
                        </span>
                      </div>
                    </div>
                    <div
                      className="position-absolute "
                      style={{
                        top: "70%",
                        left: "10%",
                        width: "30%",
                        transform: "rotate(-360deg)",
                      }}
                    >
                      <span
                        className="letraBodyInside"
                        style={{ color: "var(--elevvabutonLogin_User)" }}
                      >
                        Pierna derecha
                      </span>
                      <div style={{ lineHeight: "1.5vmin" }}>
                        {" "}
                        <span className="letraBodyInside">
                          {Array.isArray(piernadegrasa) ? (
                            piernadegrasa[0] >= 0 ? (
                              <i className="bi bi-caret-up-fill"></i>
                            ) : (
                              <i className="bi bi-caret-down-fill"></i>
                            )
                          ) : null}{" "}
                          Grasa corporal:
                          {stepIndex == 4
                            ? "5,8%"
                            : Array.isArray(piernadegrasa)
                            ? dejarsolonumber(piernadegrasa[0].toFixed(1)) + "%"
                            : piernadegrasa.toFixed(1) + "%"}
                        </span>
                        <br />
                        <span className="letraBodyInside">
                          {Array.isArray(piernade) ? (
                            piernade[0] >= 0 ? (
                              <i
                                className="bi bi-caret-up-fill"
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                                className="bi bi-caret-down-fill"
                              ></i>
                            )
                          ) : null}{" "}
                          Masa muscular:{" "}
                          {stepIndex == 4
                            ? "37 kg"
                            : Array.isArray(piernade)
                            ? dejarsolonumber(piernade[0].toFixed(1)) + "Kg"
                            : piernade.toFixed(1) + "Kg"}
                        </span>
                      </div>
                    </div>
                    <div
                      className="position-absolute"
                      style={{
                        top: "70%",
                        right: "10%",
                        width: "30%",
                        textAlign: "right",
                      }}
                    >
                      <span
                        className="letraBodyInside "
                        style={{ color: "var(--elevvabutonLogin_User)" }}
                      >
                        Pierna izquierda
                      </span>
                      <div style={{ lineHeight: "1.5vmin" }}>
                        {" "}
                        <span className="letraBodyInside ">
                          {/* Grasa corporal: 5,8% */}
                          Grasa corporal:
                          {stepIndex == 4
                            ? "5,8%"
                            : Array.isArray(piernaizgrasa)
                            ? dejarsolonumber(piernaizgrasa[0].toFixed(1)) + "%"
                            : piernaizgrasa.toFixed(1) + "%"}
                          {Array.isArray(piernaizgrasa) ? (
                            piernaizgrasa[0] >= 0 ? (
                              <i
                                className="bi bi-caret-up-fill"
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  color: "var(--elevvabutonLogin_User)",
                                }}
                                className="bi bi-caret-down-fill"
                              ></i>
                            )
                          ) : null}
                        </span>
                        <br />
                        <span className="letraBodyInside ">
                          {/* Masa muscular: 37 kg{" "} */}
                          Masa muscular:
                          {stepIndex == 4
                            ? "37 kg"
                            : Array.isArray(piernaiz)
                            ? dejarsolonumber(piernaiz[0].toFixed(1)) + "Kg"
                            : piernaiz.toFixed(1) + "Kg"}
                          {Array.isArray(piernaiz) ? (
                            piernaiz[0] >= 0 ? (
                              <i className="bi bi-caret-up-fill"></i>
                            ) : (
                              <i className="bi bi-caret-down-fill"></i>
                            )
                          ) : null}{" "}
                        </span>
                      </div>
                    </div>
                    <span
                      className="position-absolute linebodyInicio"
                      style={{ top: "45%", left: "10%", width: "38%" }}
                    >
                      <hr />
                      <span
                        className="rounded-circle position-absolute "
                        style={{
                          background: "var(--elevvaTexttitleLow)",
                          width: "8px",
                          height: "8px",
                          top: "40%",
                          left: "100%",
                        }}
                      ></span>
                    </span>
                    <span
                      className="position-absolute linebodyInicio"
                      style={{
                        top: "32%",
                        right: "10%",
                        width: "30%",
                        transform: "rotate(-180deg)",
                      }}
                    >
                      <hr />
                      <span
                        className="rounded-circle position-absolute "
                        style={{
                          background: "var(--elevvaTexttitleLow)",
                          width: "8px",
                          height: "8px",
                          top: "40%",
                          left: "100%",
                        }}
                      ></span>
                    </span>
                    <span
                      className="position-absolute linebodyInicio"
                      style={{ top: "66%", left: "10%", width: "35%" }}
                    >
                      <hr />
                      <span
                        className="rounded-circle position-absolute"
                        style={{
                          background: "var(--elevvaTexttitleLow)",
                          width: "8px",
                          height: "8px",
                          top: "40%",
                          left: "100%",
                        }}
                      ></span>
                    </span>
                    <span
                      className="position-absolute linebodyInicio"
                      style={{
                        top: "66%",
                        right: "10%",
                        width: "35%",
                        transform: "rotate(-180deg)",
                      }}
                    >
                      <hr />
                      <span
                        className="rounded-circle position-absolute"
                        style={{
                          background: "var(--elevvaTexttitleLow)",
                          width: "8px",
                          height: "8px",
                          top: "40%",
                          left: "100%",
                        }}
                      ></span>
                    </span>
                    {/* <span
                        className=" row"
                        style={{
                          position: "absolute",

                          padding: "1% 0%",
                          bottom: "5%",
                          width: "15vmin",
                          borderRadius: "10px",
                          left: "11%",
                          border: "1px solid var(--elevvaTexttitleLow)",
                        }}
                      >
                        <span className="letraBodyInsidemoresmall ">
                          Grasa corporal:Grasa corporal
                        </span>
                        <span className="letraBodyInsidemoresmall ">
                          Mm:Masa muscular
                        </span>
                      </span> */}
                    <button
                      className="m-auto d-flex position-absolute"
                      style={{
                        color: "var(--white)",
                        position: "absolute",
                        bottom: "5%",
                        right: "5%",
                        padding: "1% 5%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        verticalAlign: "middle",
                        background: "var(--elevva)",
                        borderRadius: "20px",
                        border: "1px solid var(--elevva)",
                        fontSize: "1.4vmin",
                      }}
                      onClick={
                        () =>
                          BlockBody ? avisoBloqueo() : navigate("/yourBody")

                        // setModalInfo(
                        //   <ModalInfo
                        //     error="false"
                        //     textButtonAction="Entendido"
                        //     handleCancellcargue={hideModal}
                        //     handleReservar={()=>setModalInfo(null)}
                        //     iconoWidth={"1.5rem"}
                        //     textSizeUp={"1.2rem"}

                        //     text={
                        //       "No tiene datos"
                        //     }
                        //   />
                        // );
                      }
                    >
                      Ver más <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div
                    style={{
                      borderRadius: "20px",
                      display: "grid",
                      gridTemplateColumns: "auto",
                      width: "50%",
                      height: "100%",
                      gap: "1%",
                    }}
                  >
                    <div
                      className="activityandprogress direcccionately"
                      style={{
                        background: "white",
                        height: "100%",
                        borderRadius: "20px",

                        padding: "2% 5%",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        {" "}
                        <span
                          className="letraPequeña my-auto"
                          style={{
                            color: "var(--elevvaTextTitle)",
                          }}
                        >
                          Mi <strong>actividad</strong>
                        </span>
                        <div className="content-select-gender">
                          <select
                            onChange={changeType}
                            id="selectActivity"
                            className="form-control"
                            name="actividad"
                            style={{
                              border: "none",
                              minWidth: "calc(5rem + 0.8vw)",
                              color: "var(--elevvaTextTitle)",
                            }}
                          >
                            <option value="99">HR</option>
                            <option value="14">Tensión</option>
                            <option value="kcal">Calorías</option>
                            <option value="distance">Distancia</option>
                          </select>
                          <i></i>
                        </div>
                        <div className="content-select-gender_activity position-relative">
                          <select
                            id="periodoSelect_dash"
                            className="form-control"
                            name="time"
                            style={{ color: "var(--elevvaTextTitle)" }}
                            onChange={changeRange}
                          >
                            <option value="">Seleccionar período</option>
                            <option value="cw">Esta semana</option>
                            <option value="cm">Este mes</option>
                            <option value="lm">Mes anterior</option>
                            <option value="cq">Este trimestre</option>
                            <option value="cy">Este Año</option>
                            <option value="ly">Año anterior</option>
                          </select>
                          <i></i>
                        </div>
                      </div>

                      {data && data[0].data.length > 0 && !BlockActivity ? (
                        <div
                          style={{
                            height: "100%",
                            maxHeight: "25vmin",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          <ResponsiveLine
                            data={data}
                            margin={{
                              top: 10,
                              right: 15,
                              bottom: 30,
                              left: 38,
                            }}
                            xScale={{
                              type: "point",
                              format: "%Y-%m-%d",
                              min: "auto",
                              max: "auto",
                            }}
                            yScale={{
                              type: "linear",
                              min: formatMin(),
                              max: "auto",
                              reverse: false,
                            }}
                            curve="monotoneX"
                            axisTop={null}
                            axisRight={null}
                            enableGridX={false}
                            lineWidth={2}
                            axisBottom={{
                              format: xAxisFormat,
                              orient: "bottom",
                              tickSize: 5,
                              tickPadding: 5,
                              tickRotation: 0,
                              legend: "",
                              legendOffset: 36,
                              legendPosition: "middle",
                            }}
                            axisLeft={
                              (data[0].data && data[0].data.length > 1) ||
                              data.length > 1
                                ? {
                                    format: yAxisFormat,
                                    orient: "left",
                                    tickSize: 4,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendOffset: 40,
                                    legendPosition: "middle",
                                    tickValues: yTicks.map(
                                      (tick) => tick.value
                                    ),
                                  }
                                : {
                                    format: yAxisFormat,
                                    orient: "left",
                                    tickSize: 4,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendOffset: 40,
                                    legendPosition: "middle",
                                  }
                            }
                            colors={[colorType(Type), "var(--elevva)"]}
                            theme={theme1}
                            pointSize={10}
                            pointColor={"#ffffff"}
                            pointBorderWidth={2}
                            pointBorderColor={{ from: "serieColor" }}
                            pointLabel="y"
                            pointLabelYOffset={-12}
                            enableArea={true}
                            areaOpacity={0.3}
                            defs={[
                              {
                                id: "gradientC",

                                type: "linearGradient",

                                colors: [
                                  { offset: 15, color: colorType(Type) },

                                  { offset: 100, color: "#ffff" },
                                ],
                              },
                            ]}
                            // areaBaselineValue={data[0].data.map((e, i) => e.y).sort()[0]}
                            fill={[{ match: "*", id: "gradientC" }]}
                            useMesh={true}
                            tooltip={(data) => {
                              // Aquí creamos nuestro tooltip personalizado
                              // Extrayendo el nombre de la serie y el valor del punto
                              const { point } = data;
                              return (
                                <div>
                                  <div
                                    className="text-center p-2 elevation-18"
                                    style={{
                                      color: "#A8C2D7",
                                      background: "#fff",
                                      fontSize: "calc(0.6rem + 0.2vmin)",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    {point.serieId} <br />{" "}
                                    {Math.round(point.data.yFormatted)}
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="m-auto d-flex justify-content-center flex-column text-center letraPequeña"
                          style={{
                            height: "100%",
                            maxHeight: "25vmin",
                            position: "relative",
                            minHeight: "20vmin",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "calc(0.4rem + 0.4vw)",
                              color: "var(--elevvaTextTitle)",
                              fontWeight: "400",
                            }}
                          >
                            No tiene datos
                          </span>
                        </div>
                      )}
                      <button
                        className="mx-auto d-flex"
                        onClick={() =>
                          BlockActivity
                            ? avisoBloqueo()
                            : navigate("/actividad")
                        }
                        style={{
                          color: "var(--white)",
                          padding: "1% 5%",
                          justifyContent: "center",

                          alignItems: "center",

                          background: "var(--elevva)",
                          borderRadius: "20px",
                          border: "1px solid var(--elevva)",
                          fontSize: "1.4vmin",
                        }}
                      >
                        Ver más <i className=" bi bi-chevron-right"></i>
                      </button>
                    </div>
                    <div
                      className="d-flex w-100"
                      style={{
                        gap: "2%",
                        margin: "1% 0px 0px",
                      }}
                    >
                      <div
                        className=" w-50 text-center"
                        style={
                          stepIndex == 6
                            ? {
                                background: "white",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                borderRadius: "20px",
                                alignItems: "center",
                                position: "relative",
                                padding: "2%",
                                gap: "1vmin",
                                zIndex: "9999",
                              }
                            : {
                                background: "white",
                                display: "flex",
                                gap: "1vmin",
                                flexDirection: "column",
                                justifyContent: "center",
                                borderRadius: "20px",
                                alignItems: "center",
                                position: "relative",
                                padding: "2%",
                              }
                        }
                      >
                        <span
                          className="letraPequeña "
                          style={{
                            color: "var(--elevvaTextTitle)",
                          }}
                        >
                          Progreso de{" "}
                          <strong>
                            <br />
                            mi entrenamiento
                          </strong>
                        </span>
                        <RangeProgress
                          idRange="number"
                          valPercentage={counter}
                        />

                        <button
                          data-effect="solid"
                          data-place="top"
                          data-tip="Acá podrás ver tu progreso de tus sesiones en tu plan de entrenamiento"
                          data-background-color="#E9E3DF"
                          data-border-radius="20px"
                          data-text-color="#394F65"
                          style={{
                            textAlign: "center",
                            color: "var(--elevva)",
                            background: "transparent",
                            border: "none",
                          }}
                        >
                          <i
                            className="bi bi-question-circle-fill"
                            style={{ fontSize: "1.4rem" }}
                          ></i>
                        </button>
                        <ReactTooltip globalEventOff="" />
                      </div>
                      <div
                        id="sesionesrecientes"
                        className="w-50"
                        style={{
                          background: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          borderRadius: "20px",
                          gap: "1.5vmin",
                          position: "relative",
                          padding: "1%",
                        }}
                      >
                        <div className="d-flex mx-auto ">
                          <span className="  letraPequeña  m-auto">
                            Sesiones <strong>recientes</strong>
                          </span>
                        </div>
                        <div>
                          {" "}
                          <PerfectScrollbar
                            className="w-100 text-center d-flex flex-column"
                            style={{
                              alignItems: "center",
                              height: "10vmin",
                              justifyContent: "stretch",
                            }}
                          >
                            {stepIndex === 7 ? (
                              <div
                                className=" text-center d-flex flex-row col-12"
                                style={{
                                  backgroundColor: "var(--colorsesiones)",
                                  borderRadius: "20px",
                                  padding: "3% 5%",
                                  width: "90%",
                                  alignItems: "center",
                                }}
                              >
                                <div className="col-2">
                                  <img src={bicicleta}></img>
                                </div>
                                <span
                                  className="m-auto letraPequeña col-7"
                                  style={{
                                    color: "var(--elevvaTextTitle)",
                                    padding: "1vmin",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    Bicicleta
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    23/05/2021
                                  </p>
                                </span>
                                <div
                                  // onClick={() => navigate(`/Usuario/Inicio`)}
                                  className=" fw-bold booking-all"
                                  style={{
                                    background: "transparent",
                                    border: "1px solid #A8C2D7",
                                    padding: "2%",
                                    fontSize: "1vmin",
                                    height: "fit-content",
                                    cursor: "pointer",
                                    color: "#A8C2D7",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span style={{ fontSize: "0.5vmin" }}>
                                    <i className="bi bi-chevron-right"></i>
                                  </span>
                                </div>
                              </div>
                            ) : copyAllEntrenamientos &&
                              copyAllEntrenamientos.id &&
                              !BlockSessions ? (
                              <div
                                className=" text-center d-flex flex-row col-12"
                                style={{
                                  backgroundColor: "var(--colorsesiones)",
                                  borderRadius: "20px",
                                  padding: "3% 5%",
                                  width: "90%",
                                  alignItems: "center",
                                }}
                              >
                                <div className="col-2">
                                  <img
                                    className="icon-sesion-dash addColorSelected"
                                    src={copyAllEntrenamientos.icon}
                                  ></img>
                                </div>
                                <span
                                  className="m-auto letraPequeña col-7"
                                  style={{
                                    color: "var(--elevvaTextTitle)",
                                    padding: "calc(0.2vmin + 0.1vw)",
                                    lineHeight: "1.2",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    {copyAllEntrenamientos.titulo}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    {Moment(copyAllEntrenamientos.date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </p>
                                </span>
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/results/${
                                        typeof copyAllEntrenamientos.id ==
                                        "number"
                                          ? copyAllEntrenamientos.id
                                          : "d" + copyAllEntrenamientos.UUid
                                      }`
                                    )
                                  }
                                  className=" fw-bold booking-all"
                                  style={{
                                    background: "transparent",
                                    border: "1px solid #A8C2D7",
                                    padding: "2%",
                                    fontSize: "1vmin",
                                    height: "fit-content",
                                    cursor: "pointer",
                                    color: "#A8C2D7",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span style={{ fontSize: "0.5vmin" }}>
                                    <i className="bi bi-chevron-right"></i>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="col-3 text-center d-flex flex-column"
                                style={{
                                  backgroundColor: "var(--colorsesiones)",
                                  borderRadius: "20px",
                                  padding: "3%",
                                  width: "80%",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="m-auto letraPequeña "
                                  style={{
                                    color: "var(--elevvaTextTitle)",
                                    padding: "1vmin",
                                    lineHeight: "110%",
                                    fontWeight: "600",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    No tienes
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "1.7vmin",
                                      margin: "auto",
                                    }}
                                  >
                                    sesiones recientes
                                  </p>
                                </span>
                              </div>
                            )}
                          </PerfectScrollbar>
                        </div>
                        <button
                          className="mx-auto d-flex"
                          onClick={
                            () =>
                              BlockSessions
                                ? avisoBloqueo()
                                : navigate("/results/all")
                            //                 if(userData.customer.had_valoration){

                            //                 alert('In process')
                            //                 }else{
                            //                   setModalInfo(
                            //   <ModalInfo
                            //     error="none"
                            //     textButtonAction='Agendar'
                            //     handleCancellcargue={hideModal}
                            //     handleReservar={()=>{
                            //                         const trainingValoration=  trainings && trainings.filter(training=>training.is_valoration ==true)
                            //                         const idValoration =trainingValoration[0].id
                            //                           navigate(`/training/${idValoration}`)}}
                            //     iconoWidth={'1.5rem'}
                            //     textSizeUp={'1.3rem'}
                            // icono={iconoFlechaElevva}
                            //     text={
                            //       `Para poder acceder <br/> a tu experiencia  de  <br/> bienestar,  <br/> debes agendar tu  <br/> clase de valoración`
                            //     }

                            //   />
                            // );
                            //                 }
                          }
                          style={{
                            color: "var(--white)",
                            padding: "1.5% 7%",
                            justifyContent: "center",

                            alignItems: "center",

                            background: "var(--elevva)",
                            borderRadius: "20px",
                            border: "1px solid var(--elevva)",
                            fontSize: "1.4vmin",
                          }}
                        >
                          Ver más <i className=" bi bi-chevron-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div
                style={{
                  height: "100%",
                  padding: "1%",
                  display: "flex",
                  gap: "0.8%",
                  gridTemplateColumns: "auto",
                  flexDirection: "column",
                }}
              >
                <div
                  id="userInfoPanel"
                  className="d-flex flex-column  text-center"
                  style={{
                    background: "var(--white)",
                    height: "100%",
                    margin: "1% 0px 0px",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    className="d-flex col-12 m-auto"
                    style={{
                      padding: "2vmin 2vmin 1vmin 2vmin",
                      height: "auto",
                    }}
                  >
                    {" "}
                    <ComponentName
                      padreclass={"d-flex col-8"}
                      lastName={userData && userData.last_name}
                      firstName={userData && userData.first_name}
                      profilePic={userData && userData.customer?.profile_pic}
                    />
                    <ComponentIcons
                      notificationsq={notifications}
                      classPadre={"d-flex col-4"}
                      fontSizeicons={"3vmin"}
                      pointIcon={"1vmin"}
                      leftIcon={"-10%"}
                      mxIcons={"mx-auto"}
                    />
                  </div>
                  <NivelCustomer
                    userData={userData}
                    padreclass={"d-flex col-12 m-auto  px-4  fw-bold"}
                    alllevels={alllevels}
                  />
                  <div className="d-flex px-4 col-12">
                    <div
                      className="d-flex"
                      style={{
                        padding: "0 1vmin",
                        justifyContent: "center",
                      }}
                    >
                      <div className="d-flex">
                        <img
                          style={{ width: "4vmin" }}
                          alt="tierra"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/tierra.svg"
                          }
                        />
                      </div>
                      <ProgressBar
                        now={ProgressLevel}
                        style={{
                          border: "1px solid var(--backgrounColorBar)",
                          borderRadius: "10px",
                          backgroundColor: "#F9F9F9",
                          width: "22vmin",
                          height: "1vmin",
                          margin: "auto 0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="position-relative"> </div>

                  <Puntoselevva
                    handleClick={referPlan}
                    padreclass={"puntosPadre"}
                    userData={userData && userData}
                  />

                  <SemanaCustomer dayselected={dayselected} />
                </div>
                <Proximas
                  clasesReserva={clasesReservadas}
                  BlockTrainingPlan={BlockTrainingPlan}
                  userData={userData && userData}
                  verplan={programaTraining}
                  planDaySelected={planDaySelected}
                  mesPrograma={mesPrograma && mesPrograma}
                  postNextDay={nextTraining}
                  idIdentificar={"actividadesPlanTrainer"}
                  widtMap={"90%"}
                  CardioTraining={(e) => CardioTraining(e)}
                  handleClick={() => {
                    const trainingValoration =
                      trainings &&
                      trainings.filter(
                        (training) => training.is_valoration == true
                      );
                    const idValoration = trainingValoration[0].id;
                    if (userData?.customer?.had_valoration) {
                      BlockTrainingPlan ? avisoBloqueo() : programaTraining();
                    } else {
                      navigate(`/training/${idValoration}`);
                    }
                  }}
                  stepIndex={stepIndex}
                />
                <NextClass
                  restrict={ModalRestrictBook}
                  heightMap={"17vmin"}
                  idIdentificar={"nextBookingPanel"}
                  clasesReservadas={clasesReservadas}
                  stepIndex={stepIndex}
                  handleClick={() =>
                    ModalRestrictBook
                      ? avisoBloqueo()
                      : navigate("/misclases/all")
                  }
                  handleClickVer={(e) => navigate(`/misclases/${e}`)}
                />
              </div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div
          className="w-100  position-relative d-md-none"
          style={{ height: "100%" }}
        >
          <div id={"perfilCustomerT"}>
            <div className="d-flex flex-column col-12 m-auto mobileDashboard">
              <div className="d-flex col-12 m-auto ">
                <ComponentName
                  padreclass={"d-flex col-7"}
                  lastName={userData && userData.last_name}
                  firstName={userData && userData.first_name}
                  profilePic={userData && userData.customer?.profile_pic}
                />
                <NivelCustomer
                  userData={userData}
                  padreclass={"d-flex col-5 m-auto fw-bold"}
                  alllevels={alllevels}
                />
              </div>
              <Puntoselevva
                handleClick={referPlan}
                padreclass={"puntosPadre"}
                userData={userData && userData}
              />
            </div>

            <SemanaCustomer dayselected={dayselected} />
          </div>
          <AllPromedios
            spaceBetween={5}
            ancho={"9rem"}
            grow={"growingTutorial"}
            alto={"100%"}
            widthSwiper={"95%"}
            id="numbers"
            idJoyride={"totalClasesTutorialMobi"}
            // buttonClick={(e) => handleClick(e)}
            textTitleOne={"Reserva tu primer "}
            textTitleTwo={"clase grupal en vivo gratis"}
            //trainingsId={trainingsId}

            className="d-flex justify-content-center flex-column"
            TrainingEstadistics={TrainingEstadistics}
            idPanel={"promediosPanelMobile"}
          />
          <div className="py-4" style={{ background: "#F9F9F9" }}>
            {laptop575.matches ? null : (
              <IngresaTension
                handleClick={updateTension}
                idJoyride={"infoArterialMobile"}
                tensionArterial={tensionArterial}
                tensionArterial1={tensionArterial1}
                widthContainer={"90%"}
              />
            )}
          </div>

          <TestSlider
            idJoyride={"coverflowSlider"}
            handleClick={(e) => {
              if (e.includes("cuerpo")) {
                BlockBody ? avisoBloqueo() : navigate("/yourBody");
              } else if (e.includes("actividad")) {
                BlockActivity ? avisoBloqueo() : navigate("/actividad");
              } else if (e.includes("recientes")) {
                BlockSessions ? avisoBloqueo() : navigate("/sesiones");
              } else {
                conditionPlan();
              }
            }}
            stepIndex={stepIndex}
          />

          <div className="px-4 py-2" style={{ background: "#F9F9F9" }}>
            {" "}
            <Proximas
              clasesReserva={clasesReservadas}
              CardioTraining={(e) => CardioTrainingMobile(e)}
              verplan={programaTrainingMobile}
              mesPrograma={mesPrograma && mesPrograma}
              postNextDay={nextTraining}
              planDaySelected={planDaySelected}
              userData={userData}
              idIdentificar={"proximasMobile"}
              widtMap={"100%"}
              handleClick={() => {
                const trainingValoration =
                  trainings &&
                  trainings.filter(
                    (training) => training.is_valoration == true
                  );
                const idValoration = trainingValoration[0].id;
                if (userData?.customer?.had_valoration) {
                  BlockTrainingPlan ? avisoBloqueo() : programaTrainingMobile();
                } else {
                  navigate(`/training/${idValoration}`);
                }
              }}
              stepIndexMob={stepIndex}
            />
          </div>

          <div className="px-4 py-2" style={{ background: "#F9F9F9" }}>
            {" "}
            <NextClass
              idIdentificar={"proximoBookMobile"}
              heightMap={"10rem"}
              clasesReservadas={clasesReservadas}
              stepIndexMob={stepIndex}
              handleClick={() => navigate("/misclases/all")}
              handleClickVer={(e) => navigate(`/misclases/${e}`)}
            />
          </div>
        </div>
        <ButtonTutorial handleClick={() => setcorrer(!correr)} />
        {modalInfoCardio}
      </PerfectScrollbar>
      {/* )} */}
    </div>
  );
};
export default Dashboard;
